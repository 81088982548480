import { takeLatest, call, put, select, fork } from 'redux-saga/effects'

import * as API from 'api'

import { selectCurrentMemberID } from 'modules/members/selectors'
import { selectLocale } from 'modules/language/selectors'
import { updateMemberSuccess } from 'modules/members/actions'

import {
  LOAD_DEVICES_PAGE,
  LINK_MEMBER_DEVICES_REQUEST,
  REMOVE_MEMBER_DEVICES_REQUEST,
  LIST_MEMBER_DEVICES_REQUEST,
  CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST,
  UNLINK_MEMBER_DEVICES_REQUEST,
} from './constants'

import {
  listMemberDevicesSuccess,
  listMemberDevicesFailure,
  linkMemberDevicesSuccess,
  linkMemberDevicesFailure,
  removeMemberDevicesSuccess,
  removeMemberDevicesFailure,
  unlinkMemberDevicesSuccess,
  unlinkMemberDevicesFailure,
  acknowledgeLinkDevicesAgreementSuccess,
  acknowledgeLinkDevicesAgreementFailure,
  setLinkingStates,
} from './actions'

export function* devicesSaga(data) {
  let memID
  if (data) {
    // populated on mobile app use
    memID = data.payload.memberID
  }

  const storeMemID = yield select(selectCurrentMemberID)
  const memberID = storeMemID || memID
  if (!memberID) {
    return
  }

  yield call(listMemberDevicesSaga, { memberID })
}

export function* listMemberDevicesSaga({ memberID }) {
  try {
    const response = yield call(API.listMemberDevices, {
      member_id: memberID,
    })
    const { devices } = response
    yield put(listMemberDevicesSuccess(devices))
  } catch (error) {
    yield put(listMemberDevicesFailure(error))
  }
}

export function* linkMemberDevicesSaga({ payload }) {
  const { memberID, vendor } = payload
  const setters = payload?.setters || {}

  const language = yield select(selectLocale)

  try {
    const response = yield call(API.linkMemberDevices, {
      member_id: memberID,
      vendor,
      vendor_type: 'hvac',
      language,
      colorScheme: 'light',
      // TODO : this should be managed from iot service
      redirectUri: `${process.env.REACT_APP_LINKING_REDIRECT_URL}&vendor=${vendor}`,
    })

    if (response.linkUrl) {
      // allow for noopener,noreferrer properties to detect popup is closed
      const popupRef = window.open(
        response.linkUrl,
        '_blank',
        'width=440px,height=600px'
      )

      try {
        // if this throws an exception then popup did not open
        popupRef.document
      } catch (err) {
        // prompt retry / open as tab modal
        yield put(
          setLinkingStates({
            isPopupEnabled: false,
            linkUrl: response.linkUrl,
          })
        )
        return // early return (handle retry in UI)
      }

      // if popup is successful check for window closed in background
      yield fork(function*() {
        // We can't determine if linking is complete from this point
        // so we refetch device list when the window is closed
        while (true) {
          if (popupRef?.closed) {
            // we could leverge the PostMessage to communicate with (this) parent window
            yield call(listMemberDevicesSaga, { memberID })
            break
          }
          yield call(delay, 1000) // check every second
        }

        // poll for latest device updates
        yield fork(function*() {
          let count = 1
          while (count <= 3) {
            yield call(delay, 3000)
            yield call(listMemberDevicesSaga, { memberID })
            count++
          }
        })
      })

      yield put(linkMemberDevicesSuccess())
    } else {
      yield put(linkMemberDevicesFailure('unable to link devices at this time'))
    }
  } catch (error) {
    yield put(linkMemberDevicesFailure('unable to link devices at this time'))
  } finally {
    if (setters?.setSubmitting) {
      setters.setSubmitting(false)
    }
  }
}

export function* removeMemberDevicesSaga({ payload }) {
  const { memberID, vendorDeviceIDs, callback } = payload
  const setters = payload?.setters || {}

  try {
    yield call(API.removeMemberDevices, {
      member_id: memberID,
      vendor_device_ids: vendorDeviceIDs,
    })
    yield put(removeMemberDevicesSuccess())

    // fork will run async; poll for latest device updates
    yield fork(function*() {
      let count = 1
      while (count <= 3) {
        yield call(delay, 3000)
        yield call(listMemberDevicesSaga, { memberID })
        count++
      }
    })

    if (callback instanceof Function) {
      callback()
    }
  } catch (error) {
    yield put(
      removeMemberDevicesFailure(
        'Unable to remove devices at this time. Please contact support.'
      )
    )
  } finally {
    if (setters?.setSubmitting) {
      setters.setSubmitting(false)
    }
  }
}

export function* unlinkMemberDevicesSaga({ payload }) {
  const { memberID, vendor, callback } = payload
  const setters = payload?.setters || {}

  try {
    yield call(API.unlinkMemberDevices, {
      member_id: memberID,
      vendor,
      vendor_type: 'hvac',
    })
    yield put(unlinkMemberDevicesSuccess())

    // fork will run async; poll for latest device updates
    yield fork(function*() {
      let count = 1
      while (count <= 3) {
        yield call(delay, 3000)
        yield call(listMemberDevicesSaga, { memberID })
        count++
      }
    })

    if (callback instanceof Function) {
      callback()
    }
  } catch (error) {
    yield put(
      unlinkMemberDevicesFailure('unable to unlink devices at this time')
    )
  } finally {
    if (setters?.setSubmitting) {
      setters.setSubmitting(false)
    }
  }
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function* acknowledgeLinkDevicesAgreement({ payload }) {
  const { hasAcknowledged, callback } = payload

  try {
    const memberID = yield select(selectCurrentMemberID)

    yield call(API.updateMember, {
      memberID: memberID,
      link_devices_enabled: hasAcknowledged,
    })

    // refetch current member data
    const res = yield call(API.getMember, { memberID: memberID })

    if (res.link_devices_enabled) {
      yield put(acknowledgeLinkDevicesAgreementSuccess())
      // persist updated current member data to store
      yield put(updateMemberSuccess(res))
      if (callback instanceof Function) {
        callback()
      }
    }
  } catch (err) {
    yield put(acknowledgeLinkDevicesAgreementFailure())
  }
}

export default function* defaultSaga() {
  yield fork(devicesSaga)
  yield takeLatest(LOAD_DEVICES_PAGE, devicesSaga)
  yield takeLatest(LIST_MEMBER_DEVICES_REQUEST, listMemberDevicesSaga)
  yield takeLatest(LINK_MEMBER_DEVICES_REQUEST, linkMemberDevicesSaga)
  yield takeLatest(UNLINK_MEMBER_DEVICES_REQUEST, unlinkMemberDevicesSaga)
  yield takeLatest(REMOVE_MEMBER_DEVICES_REQUEST, removeMemberDevicesSaga)
  yield takeLatest(
    CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST,
    acknowledgeLinkDevicesAgreement
  )
}
