import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/fanfareLogo.png'
import NeueKabelBoldFont from 'assets/fonts/Neue-Kabel-Bold.ttf'
const isDev = !window.location.href.includes('app.gogriddy')

const base = '#0b141c'
const primary = '#e58740'
const secondary = '#0b141c'
const blue = '#e58740'
const gray = '#e9edf2'
const grayMedium = '#afbbc3'
const grey = '#666666'
const darkGray = '#afbbc3'
const lightGray = '#f7f7f7'
const pink = '#ED0874'
const yellow = '#dfd845'
const darkGreen = '#dfd845'
const green = '#dfd845'
const greenPromo = '#dfd845'
const error = '#dd2c00'
const neutral1 = '#0b141c'
const neutral2 = '#0b141c'
const neutral3 = '#afbbc3'
const neutral4 = '#0b141c'
const neutral5 = '#0b141c'
const white = '#fefefe'
const footerColor = '#0b141c'
const darkerPrimary = '#e58740'

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  lightGray,
  darkGray,
  pink,
  yellow,
  darkGreen,
  green,
  greenPromo,
  error,
  gradient: 'white',
  link: '#e58740',
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  darkerPrimary,
  buttonColor: base,
  buttonColorSecondary: base,
  buttonBorder: base,
  buttonBackground: primary,
  costColors: {
    first: '#f38762',
    second: '#f7935c',
    third: '#6b497e',
    fourth: '#333e6b',
  },
  usageGraph: {
    isPending: '#f7f7f7',
    isNotPending: primary,
    gradientStart: primary,
    gradientEnd: primary,
    base: primary,
  },
  ratePanel: '#cc7a00',
  rateText: 'white',
  offerDetails: secondary,
  offerShortDescription: '#cc7a00',
  renewalBanner: {
    text: 'white',
    background: primary,
  },
  lighterSecondary: '#f7f7f7',
}

export const companyInfo = {
  portalName: 'Fanfare Energy',
  fullName: 'Fanfare Energy',
  shortName: 'Fanfare Energy',
  email: 'hello@fanfare.energy',
  phone: '+1 (855) 955-5303',
  text_number: '',
  hoursOfOperation: <Trans>Monday - Friday 9:00 AM - 6:00 PM CST</Trans>,
  signUpLink: 'https://fanfareenergy.com', //TODO check link
  defaultTimeZone: 'US/Central',
  homepageLink: 'https://fanfareenergy.com',
  mixpanelToken: '',
  faviconLink: '',
  allowMakePaymentEnterNewCard: true,
}

export const logoConfig = {
  localPath: 'assets/images/logos/fanfareLogo.png',
  url: '',
  width: 'auto',
  height: '45px',
  useLocal: true,
  imgType: 'png',
  logo,
  wrapper: {
    padding: 8,
    backgroundColor: 'transparent',
    marginLeft: -40,
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif" // set to default baseFamily
const customFont = 'Inter'

export const font = {
  extraBold: '700',
  semiBold: '600',
  medium: '400',
  normal: '350',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: 'Neue Kabel';
  src: url('${NeueKabelBoldFont}') format('opentype');
};

`

export const routes = {
  homePagePath: '/account',
  wholesale: false,
  devices: false,
}
export const features = {
  ADD_FUNDS_PAST_DUE_BALANCE: false,
  ADD_FUNDS_CURRENT_BALANCE: false,
  ADD_FUNDS_LAST_BILL_AMOUNT: true,
  ADD_FUNDS_BALANCE_DUE: true,
  ACCOUNT_BALANCE_DUE: true,
  ACCOUNT_PAST_DUE_BALANCE: false,
  ACCOUNT_CURRENT_BALANCE: false,
  CURRENT_BALANCE_BANNER: false,
  REFER_A_FRIEND: false,
  RECHARGE_AMOUNT: false,
  ADD_METER: false,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  RENEWAL_BANNER: false,
  PLUS_BANNER: false,
  SHOW_SIGN_UP_LINK: true,
  DISABLE_BILLING_TOGGLES: false,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://config.d3fbicgal063f7.amplifyapp.com'
    : 'https://fanfareenergy.com', //TODO link
  ZENDESK_SECTIONS: '',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: false,
  cost_dollars: false,
}
