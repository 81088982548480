import * as abacusEnergy from './abacus-energy'
import * as shift from './shift'
import * as voltus from './voltus'
import * as juice from './juice'
import * as flagshipPower from './flagship-power'
import * as mothership from './mothership'
import * as think from './think'
import * as abundance from './abundance'
import * as thinkStripe from './think-stripe'
import * as branchEnergy from './branch-energy'
import * as fanfare from './fanfare'

// selected profile make selection based on command line arguments or build config
// eslint-disable-next-line import/no-mutable-exports
let selectedProfile

switch (process.env.REACT_APP_PROFILE_NAME) {
  case 'abacusEnergy': {
    selectedProfile = abacusEnergy
    break
  }
  case 'shift': {
    selectedProfile = shift
    break
  }
  case 'voltus': {
    selectedProfile = voltus
    break
  }
  case 'juice': {
    selectedProfile = juice
    break
  }
  case 'flagship-power': {
    selectedProfile = flagshipPower
    break
  }
  case 'mothership': {
    selectedProfile = mothership
    break
  }
  case 'think': {
    selectedProfile = think
    break
  }

  case 'abundance': {
    selectedProfile = abundance
    break
  }

  case 'think-stripe': {
    selectedProfile = thinkStripe
    break
  }

  case 'branch-energy': {
    selectedProfile = branchEnergy
    break
  }

  case 'fanfare': {
    selectedProfile = fanfare
    break
  }

  default: {
    selectedProfile = think
  }
}

export const color = {
  ...selectedProfile.color,
}

export const font = {
  ...selectedProfile.font,
  fontFace: selectedProfile.fontFace,
}

export const logoConfig = {
  ...selectedProfile.logoConfig,
}

export const companyInfo = {
  ...selectedProfile.companyInfo,
}

export const routes = {
  ...selectedProfile.routes,
}

export const features = {
  ...selectedProfile.features,
}

export const url = {
  ...selectedProfile.url,
  SIGN_UP: selectedProfile.companyInfo.signUpLink,
  HOME_PAGE: selectedProfile.companyInfo.homepageLink || '/',
}

export const graphs = {
  ...selectedProfile.graphs,
}

export default selectedProfile
