import * as C from './constants'

export const loadDevicesPage = payload => ({
  type: C.LOAD_DEVICES_PAGE,
  payload,
})

export const linkMemberDevicesRequest = payload => ({
  type: C.LINK_MEMBER_DEVICES_REQUEST,
  payload,
})

export const linkMemberDevicesSuccess = () => ({
  type: C.LINK_MEMBER_DEVICES_SUCCESS,
})

export const linkMemberDevicesFailure = payload => ({
  type: C.LINK_MEMBER_DEVICES_FAILURE,
  payload,
})

export const removeMemberDevicesRequest = payload => ({
  type: C.REMOVE_MEMBER_DEVICES_REQUEST,
  payload,
})

export const removeMemberDevicesSuccess = () => ({
  type: C.REMOVE_MEMBER_DEVICES_SUCCESS,
})

export const removeMemberDevicesFailure = payload => ({
  type: C.REMOVE_MEMBER_DEVICES_FAILURE,
  payload,
})

export const unlinkMemberDevicesRequest = payload => ({
  type: C.UNLINK_MEMBER_DEVICES_REQUEST,
  payload,
})

export const unlinkMemberDevicesSuccess = () => ({
  type: C.UNLINK_MEMBER_DEVICES_SUCCESS,
})

export const unlinkMemberDevicesFailure = payload => ({
  type: C.UNLINK_MEMBER_DEVICES_FAILURE,
  payload,
})

export const listMemberDevicesRequest = () => ({
  type: C.LIST_MEMBER_DEVICES_REQUEST,
})

export const listMemberDevicesSuccess = devices => ({
  type: C.LIST_MEMBER_DEVICES_SUCCESS,
  payload: devices,
})

export const listMemberDevicesFailure = payload => ({
  type: C.LIST_MEMBER_DEVICES_FAILURE,
  payload: payload,
})

export const acknowledgeLinkDevicesAgreementRequest = payload => ({
  type: C.CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST,
  payload: payload,
})

export const acknowledgeLinkDevicesAgreementSuccess = () => ({
  type: C.CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_SUCCESS,
})

export const acknowledgeLinkDevicesAgreementFailure = payload => ({
  type: C.CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_FAILURE,
  payload: payload,
})

export const setLinkingStates = payload => ({
  type: C.SET_LINKING_STATES,
  payload: payload,
})
